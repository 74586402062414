// 
// user.js
// Use this to write your custom JS
//


// svg map js
var mapInfo = document.getElementById('map-info');
var usMapBox = document.getElementById('us-map');
var defaultText = "Select a state to learn more."

if (usMapBox != null) {
  usMapBox.addEventListener('click', function (e) {
    if (e.target.tagName == 'path' || e.target.tagName == 'circle') {
      var contents = e.target.dataset.info;
      if (contents == null) {
        contents = defaultText
      }
      mapInfo.innerHTML = contents;
    }
  })

  // default
  mapInfo.innerHTML = document.getElementById("IL").dataset.info
}
